<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div>
    <CModal
      :title="$t('service.modalParamTitle')"
      :show.sync="isShowModal"
      :closeOnBackdrop="false"
      class="hide-close-button"
    >

      <CSelect
        label="Fabric-admin-tool*"
        :options="fabricAdminToolForSelect"
        horizontal
        required
        placeholder=" "
        addLabelClasses="font-weight-bold"
        :value.sync="form.fabricAdminTool"
        :isValid="isValid.fabricAdminTool"
        :tooltipFeedback=false
        :invalidFeedback="validationMessages.required"
        @change="$v.form.fabricAdminTool.$touch()"
      />

      <div slot="footer" class="footer-buttons">
        <CButton
          type="button"
          class="btn btn-secondary"
          @click="closeModal"
        > {{ $t('common.cancel') }} </CButton>
        <CButton
          type="button"
          class="btn btn-primary"
          :disabled="!isFormValid"
          @click="createParam"
        > {{ $t('common.request') }} </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import messages from '../messages'


export default {
  name: 'ModalParam',

  props: {
    blockchain_net_id: String,
    isShowModal: Boolean,
    fabricAdminToolList: Array,
  },

  data() {
    return {
      validationMessages: messages.validation,
      form: {
        fabricAdminTool: null,
      },
    }
  },

  validations: {
    form: {
      fabricAdminTool: {
        required
      }
    }
  },

  computed: {
    isValid() {
      let fabricAdminTool;

      if (this.$v.form.fabricAdminTool.$dirty)
        fabricAdminTool = !this.$v.form.fabricAdminTool.$invalid

      return { fabricAdminTool }
    },

    isFormValid() {
      return !this.$v.form.$invalid
    },

    fabricAdminToolForSelect() {
      return this.fabricAdminToolList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        }
      })
    },
  },

  methods: {

    closeModal() {
      this.form = {
        fabricAdminTool: null,
      }
      this.$v.$reset()
      this.$emit('closeModal')
    },

    createParam() {
      const data = {
        fabric_endpoint_id: this.form.fabricAdminTool,
      }
      this.$emit('createParam', data)
      this.closeModal()
    }
  },
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

</style>
