<template>
  <CModal :show="isOpened" size="lg" class="env-variable-modal">
    <div v-if="hasError" class="overlay">
      <span>{{ $t("common.somethingWentWrong") }}</span>
    </div>
    <div v-if="loading" class="overlay">
      <span>{{ $t("common.loading") }}...</span>
    </div>

    <div slot="header">
      <h3>{{ $t("service.envVariable") }}</h3>
    </div>
    <div class="add-new d-flex align-items-center w-100">
      <CInput
        :horizontal="{ input: 'env-label flex-grow-1 mr-3' }"
        type="text"
        :placeholder="$t('common.name')"
        v-model="newEnv.name"
      />
      <CInput
        :horizontal="{ input: 'env-input flex-grow-1 mr-3' }"
        :placeholder="$t('common.value')"
        type="text"
        v-model="newEnv.value"
      />
      <CButton
        type="button"
        class="btn btn-primary"
        @click="addVariable()"
        :disabled="!newEnv.name || !newEnv.value"
      >
        {{ $t("common.add") }}
      </CButton>
    </div>
    <div class="env-content d-flex flex-column">
      <ul class="env-list mb-3">
        <li
          v-for="([key, value], index) in variables"
          :key="key"
          class="env-item"
        >
          <div class="env-label">
            <span>{{ key }}</span>
          </div>

          <div class="env-input-wrapper">
            <CInput
              :horizontal="{ input: 'env-input flex-grow-1' }"
              type="text"
              :name="key"
              v-model="variables[index][1]"
            />
            <CButton
              @click.stop.prevent="deleteVariable(index)"
              size="sm"
              class="ml-2"
            >
              <CIcon name="cil-trash" size="sm" />
            </CButton>
          </div>
        </li>
      </ul>
    </div>

    <div slot="footer">
      <CButton
        type="button"
        class="btn btn-secondary mr-2"
        @click="cancel"
      >
        {{ $t("common.cancel") }}
      </CButton>

      <CButton
        type="button"
        class="btn btn-primary"
        :disabled="!hasChanges || loading"
        @click="setVariables"
      >
        {{ $t("common.save") }}
      </CButton>
    </div>
  </CModal>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";

import utilMessages from "@/utils/messages";

export default {
  name: "EnvVariableModal",
  props: {
    isOpened: Boolean,
    endpointId: String,
  },
  data() {
    return {
      originalValues: [],
      variables: [],
      loading: false,
      hasError: false,
      newEnv: {
        name: "",
        value: "",
      },
    };
  },
  methods: {
    ...mapActions(["getEnvVariables", "setEnvVariables", "subscribe"]),
    async setVariables() {
      this.loading = true;
      const response = await this.setEnvVariables({
        id: this.endpointId,
        variables: this.variables,
      });

      const subscribtion = await this.subscribe({
        key: response.topic_id,
        handler: (data) => {
          subscribtion.unsubscribe();
          this.checkParametersWereSet(data);
        },
      });
    },
    cancel() {
      this.$emit("onClose");
      this.hasError = false;
      this.variables = [];
      this.newEnv.name = "";
      this.newEnv.value = "";
    },
    async initData() {
      await this.requestVariables();
    },
    async requestVariables() {
      try {
        this.loading = true;
        const topicId = await this.getEnvVariables(this.endpointId);
        const subscription = await this.subscribe({
          key: topicId,
          handler: (data) => {
            subscription.unsubscribe();
            this.processVariables(data);
          },
        });
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(error));
        this.hasError = true;
        this.loading = false;
      }
    },
    deleteVariable(index) {
      this.variables.splice(index, 1);
    },
    addVariable() {
      if (!this.newEnv.name || !this.newEnv.value) {
        return;
      }

      const variable = this.variables.find(
        ([key, _]) => key === this.newEnv.name
      );

      if (variable) {
        const message = this.$i18n
          .t("service.variableAlreadyExists")
          .replace("#@NAME@#", this.newEnv.name);
        this.$toast.error(message);
        return;
      }

      this.variables.unshift([this.newEnv.name, this.newEnv.value]);
      this.newEnv.name = "";
      this.newEnv.value = "";
    },
    processVariables(topicResponse) {
      const { variables } = topicResponse.response;

      this.variables = Object.entries(variables);
      this.originalValues = Object.entries(variables);
      this.loading = false;
    },
    checkParametersWereSet(response) {
      if (!response.success) {
        return;
      }
      this.$toast.success(this.$i18n.t("service.envUpdatedSuccessfully"));
      this.loading = false;
    },
  },
  computed: {
    hasChanges() {
      return !_.isEqual(this.originalValues, this.variables);
    },
  },
  watch: {
    async isOpened(opened) {
      if (!opened) {
        return;
      }
      await this.initData();
    },
  },
};
</script>

<style>
.env-variable-modal {
  position: relative;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.variables-table {
  width: 100%;
}

.env-content {
  display: flex;
  align-items: center;

  position: relative;

  overflow-y: auto;
  padding-right: 25px;
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.env-label {
  flex: 1 0 50%;
}

.env-input-wrapper {
  flex: 1 0 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.env-input {
  width: 100%;
}

.env-item {
  display: flex;
}

.env-list {
  margin: 0;
  padding: 0;

  width: 100%;
  max-height: 300px;
  height: 300px;
}

.add-new {
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .env-item {
    flex-direction: column;
  }
}
</style>
