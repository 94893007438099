<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CForm>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol lg="8" sm="6">
                <span class="bold"
                  >{{ $t("service.service") }} {{ endpoint.hostname }}</span
                >
                <fa-icon
                  v-if="hasStatus"
                  icon="circle"
                  size="1x"
                  class="statusMargin"
                  :style="getStatusColor"
                  :title="getInfoFromStatus"
                />
                <CButton
                  v-if="getLifeCycle.status === 'error'"
                  size="sm"
                  class="p-0 m-3"
                  @click="goToTopic"
                >
                  {{ $t("service.log") }}
                </CButton>
              </CCol>
              <CCol lg="4" sm="6" class="alignEnd">
                <span v-if="hasStatus"
                  >{{ $t("service.updatedAt") }}
                  {{ getLastUpdate(getLifeCycle.lifeCycleLastUpdate) }}</span
                >
                <fa-icon
                  v-if="!hasStatus"
                  icon="sync"
                  size="1x"
                  style="color: orange"
                  class="animated"
                  @click="goToTopicLog(topicCheckUpLifeCycle)"
                />
                <fa-icon
                  v-if="hasStatus"
                  icon="sync"
                  size="1x"
                  class="statusStyles"
                  @click="lifecycleRefresh"
                  style="color: black"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="12" sm="12">
                <CButton
                  :color="isUpActive ? 'primary' : 'secondary'"
                  class="mr-1"
                  :disabled="!isUpActive"
                  @click="lifecycleUp"
                >
                  <CIcon name="cil-media-play" size="sm" />
                  {{ $t("service.init") }}
                </CButton>
                <CButton
                  :color="isStartActive ? 'success' : 'secondary'"
                  class="mr-1"
                  :disabled="!isStartActive"
                  @click="lifecycleStart"
                >
                  <CIcon name="cil-media-play" size="sm" />
                  {{ $t("service.start") }}
                </CButton>
                <CButton
                  :color="isStopActive ? 'success' : 'secondary'"
                  class="mr-1"
                  :disabled="!isStopActive"
                  @click="lifecycleStop"
                >
                  <CIcon name="cil-media-stop" size="sm" />
                  {{ $t("service.stop") }}
                </CButton>
                <CButton
                  :color="isDownActive ? 'danger' : 'secondary'"
                  class="mr-1"
                  :disabled="!isDownActive"
                  @click="lifecycleDown"
                >
                  <CIcon name="cil-trash" size="sm" />
                  {{ $t("service.down") }}
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <p></p>
                <div v-if="getIncidentsInfo.length > 0">
                  <p v-for="(incident, i) in getIncidentsInfo" :key="i">
                    <fa-icon icon="window-close" size="1x" style="color: red" />
                    {{ incident }}
                  </p>
                </div>
                <div v-else>
                  <p>
                    <fa-icon icon="check" size="1x" style="color: green" />
                    {{ $t("service.tlsOk") }}
                  </p>
                </div>
              </CCol>
            </CRow>
            <div class="env-variable-wrapper d-flex justify-content-end">
              <CButton
                variant="ghost"
                color="primary"
                :disabled="isUpActive || isStartActive"
                @click="onEnvModalShow()"
                >{{ $t("service.envVariable") }}</CButton
              >
            </div>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="8" lg="8">
                <span class="bold">{{
                  $t("service.networksAvailability")
                }}</span>
              </CCol>
              <CCol sm="4" lg="4" class="alignEnd">
                <div class="inlineBox" v-if="getNetworks.networks.lastUpdate">
                  {{ $t("service.updatedAt") }}
                  {{ getLastUpdate(getNetworks.networks.lastUpdate) }}
                </div>
                <fa-icon
                  icon="sync"
                  size="1x"
                  :class="
                    getNetworks.networks.isLoading
                      ? 'syncAnimated'
                      : 'statusStyles'
                  "
                  @click="
                    getNetworks.networks.isLoading
                      ? goToTopicLog(availabilityTopicId)
                      : getterAvailability()
                  "
                  style="color: black"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody v-if="getNetworks.networks.items.length > 0">
            <CDataTable
              striped
              :items="getNetworks.networks.items"
              header="header"
              :fields="fieldsNetworks"
            >
              <template #outboundTelnet="item">
                <td>
                  <CBadge v-if="item.item.outbound_telnet" color="success">{{
                    $t("service.available")
                  }}</CBadge>
                  <CBadge v-else color="danger">{{
                    $t("service.availableNot")
                  }}</CBadge>
                </td>
              </template>
              <template #outboundOpensslConnect="item">
                <td>
                  <CBadge
                    v-if="item.item.outbound_openssl_connect"
                    color="success"
                    >{{ $t("service.available") }}</CBadge
                  >
                  <CBadge v-else color="danger">{{
                    $t("service.availableNot")
                  }}</CBadge>
                </td>
              </template>
            </CDataTable>
          </CCardBody>

          <ModalParam
            :blockchain_net_id="bNetID"
            :isShowModal="isShowModal"
            :fabricAdminToolList="modalParam.fabricAdminToolList"
            @closeModal="onCloseModal"
            @createParam="onUpdateFabricTool"
          />
        </CCard>

        <CCard v-if="endpoint.type === 'peer'">
          <CCardHeader>
            <CRow>
              <CCol sm="8" lg="8">
                <span class="bold">{{ $t("service.channels") }}</span>
              </CCol>
              <CCol sm="4" lg="4" class="alignEnd">
                <div class="inlineBox" v-if="getChannels.channels.lastUpdate">
                  {{ $t("service.updatedAt") }}
                  {{ getLastUpdate(getChannels.channels.lastUpdate) }}
                </div>
                <fa-icon
                  icon="sync"
                  size="1x"
                  :class="
                    getChannels.channels.isLoading
                      ? 'syncAnimated'
                      : 'statusStyles'
                  "
                  @click.prevent="
                    getChannels.channels.isLoading
                      ? goToTopicLog(channelTopicId)
                      : showModalChannels()
                  "
                  style="color: black"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody v-if="getChannels.channels.items.length > 0">
            <CDataTable
              striped
              :items="getChannels.channels.items"
              header="header"
              :fields="channelsFields"
            >
              <template #isJoined="item">
                <td>
                  <CBadge
                    v-if="
                      item.item.is_joined ||
                      getConnectedList.includes(item.item.id)
                    "
                    color="success"
                  >
                    {{ $t("service.connected") }}
                  </CBadge>
                  <CBadge v-else color="warning">
                    {{ $t("service.connectedNot") }}
                  </CBadge>
                </td>
              </template>
              <template #channelActions="item">
                <td>
                  <button
                    v-if="!item.item.is_joined"
                    @click="
                      getChannelStatus && getCurrentChannel === item.item.id
                        ? goToTopicLog(item.item.id)
                        : showModalConnectChannel(item)
                    "
                    class="clearButton"
                  >
                    <CIcon
                      v-if="
                        getChannelStatus && getCurrentChannel === item.item.id
                      "
                      name="cilSync"
                      size="lg"
                      class="statusStylesSync"
                      style="color: blue"
                    />
                    <CIcon
                      v-else
                      name="cil-input"
                      size="lg"
                      class="statusStyles"
                      style="color: blue"
                    />
                    <span class="connectColor">{{
                      $t("service.connect")
                    }}</span>
                  </button>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>

        <CCard v-if="endpoint.type === 'peer'">
          <CCardHeader>
            <CRow>
              <CCol sm="8" lg="8"
                ><span class="bold">{{
                  $t("service.smartContracts")
                }}</span></CCol
              >
              <CCol sm="4" lg="4" class="alignEnd">
                <div
                  class="inlineBox"
                  v-if="getChainCodes.chainCodes.lastUpdate"
                >
                  {{ $t("service.updatedAt") }}
                  {{ getLastUpdate(getChainCodes.chainCodes.lastUpdate) }}
                </div>
                <fa-icon
                  icon="sync"
                  size="1x"
                  :class="
                    getChainCodes.chainCodes.isLoading
                      ? 'syncAnimated'
                      : 'statusStyles'
                  "
                  @click.prevent="
                    getChainCodes.chainCodes.isLoading
                      ? goToTopicLog(chainCodesTopicId)
                      : showModalChainCodes()
                  "
                  style="color: black"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody v-if="getChainCodes.chainCodes.items.length > 0">
            <CDataTable
              striped
              header="header"
              :items="getChainCodes.chainCodes.items"
              :fields="fieldsContract"
            >
              <template #id_hash="item">
                <td>
                  <CBadge v-if="item.item.id_hash !== ''" color="success">
                    {{ $t("service.installed") }} {{ item.item.id_hash }}
                  </CBadge>
                  <CBadge v-else color="warning">
                    {{ $t("service.notInstalled") }}
                  </CBadge>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CForm>
    </CCol>

    <EnvVariablesModalVue
      :isOpened="isEnvModalOpened"
      :endpointId="endpoint.id"
      @onClose="onEnvModalClose()"
    />
  </CRow>
</template>

<script>
import utilMessages from "@/utils/messages";
import moment from "moment";
import { mapGetters } from "vuex";
import EnvVariablesModalVue from "./EnvVariablesModal.vue";
import ModalParam from "./ModalParam.vue";

export default {
  components: {
    ModalParam,
    EnvVariablesModalVue,
  },
  name: "ServerList",
  data() {
    return {
      endpoint: {},
      allChannels: [],
      allContracts: [],
      allNetworks: [],
      bNetID: null,
      isShowModal: false,
      channelsFields: [
        { key: "name", label: this.$t("service.channelName") },
        { key: "isJoined", label: this.$t("service.channelStatus") },
        { key: "channelActions", label: "" },
      ],
      fieldsContract: [
        { key: "name", label: this.$t("service.channelName") },
        { key: "version", label: this.$t("service.version") },
        { key: "id_hash", label: this.$t("service.hash") },
      ],
      fieldsNetworks: [
        { key: "hostname", label: this.$t("service.host") },
        { key: "port", label: this.$t("service.port") },
        { key: "outboundTelnet", label: this.$t("service.telnet") },
        { key: "outboundOpensslConnect", label: this.$t("service.ssh") },
      ],
      header: {
        type: true,
        default: "",
      },
      modalParam: {
        fabricAdminToolList: [],
      },
      channelId: null,
      topicCheckUpLifeCycle: null,
      availabilityTopicId: null,
      channelTopicId: null,
      chainCodesTopicId: null,
      isEnvModalOpened: false,
    };
  },
  async mounted() {
    await this.getServices();
  },

  computed: {
    ...mapGetters([
      "getLifeCycle",
      "getEndpointTopicId",
      "getChannels",
      "getChainCodes",
      "getNetworks",
      "getChannelsConnects",
      "getIncidents",
    ]),

    isUpActive() {
      return (
        this.getEndpointTopicId === this.getLifeCycle.id &&
        this.getLifeCycle.status === "down"
      );
    },
    isStartActive() {
      return (
        this.getEndpointTopicId === this.getLifeCycle.id &&
        this.getLifeCycle.status === "stopped"
      );
    },
    isStopActive() {
      return (
        this.getEndpointTopicId === this.getLifeCycle.id &&
        this.getLifeCycle.status === "started"
      );
    },
    isDownActive() {
      return (
        this.getEndpointTopicId === this.getLifeCycle.id &&
        ["started", "stopped"].includes(this.getLifeCycle.status)
      );
    },
    hasStatus() {
      return (
        this.getEndpointTopicId &&
        this.getEndpointTopicId === this.getLifeCycle.id &&
        this.getLifeCycle.status !== ""
      );
    },
    getInfoFromStatus() {
      const statuses = {
        down: this.$t("service.badgeNotInit"),
        stopped: this.$t("service.badgeStopped"),
        started: this.$t("service.badgeStarted"),
        error: this.$t("service.badgeError"),
      };
      return statuses[this.getLifeCycle.status];
    },
    getStatusColor() {
      const statuses = {
        down: "orange",
        stopped: "orange",
        started: "green",
        error: "red",
      };
      return { color: statuses[this.getLifeCycle.status] };
    },
    getChannelStatus() {
      const { isChannelConnectLoading } = this.getChannelsConnects;
      return isChannelConnectLoading;
    },
    getConnectedList() {
      const { connectedIdList } = this.getChannelsConnects;
      return connectedIdList;
    },
    getCurrentChannel() {
      const { channelId } = this.getChannelsConnects;
      return channelId;
    },
    getIncidentsInfo() {
      const incidents = this.getIncidents;
      const customList = {
        tls_certificate_local_tls_mismatch: this.$t(
          "service.tls_certificate_local_tls_mismatch"
        ),
        tlsca_certificate_local_tls_mismatch: this.$t(
          "service.tlsca_certificate_local_tls_mismatch"
        ),
        tlsca_certificate_local_msp_mismatch: this.$t(
          "service.tlsca_certificate_local_msp_mismatch"
        ),
        tlsca_certificate_msp_mismatch: this.$t(
          "service.tlsca_certificate_msp_mismatch"
        ),
        ca_certificate_msp_mismatch: this.$t(
          "service.ca_certificate_msp_mismatch"
        ),
        endpoint_msp_certificate_near_to_expire: this.$t(
          "service.endpoint_msp_certificate_near_to_expire"
        ),
        endpoint_msp_certificate_expired: this.$t(
          "service.endpoint_msp_certificate_expired"
        ),
        endpoint_tls_certificate_near_to_expire: this.$t(
          "service.endpoint_tls_certificate_near_to_expire"
        ),
        endpoint_tls_certificate_expired: this.$t(
          "service.endpoint_tls_certificate_expired"
        ),
      };
      const keysIncident = Object.keys(customList);
      const filtered = incidents.filter((element) =>
        keysIncident.includes(element.type)
      );
      const incidentMap = [];
      if (filtered.length > 0) {
        filtered.forEach((incident) =>
          incidentMap.push(customList[incident.type])
        );
      }
      return incidentMap;
    },
  },

  methods: {
    onEnvModalClose() {
      this.isEnvModalOpened = false;
      document.body.classList.remove("scroll-none");
    },
    onEnvModalShow() {
      this.isEnvModalOpened = true;
      document.body.classList.add("scroll-none");
    },
    async getServices() {
      if (!this.$route.params.id) {
        return;
      }

      this.channelId = this.$route.params.id;

      try {
        await this.$store.dispatch("resetAllData");
        this.endpoint = await this.$store.dispatch(
          "getEndpointById",
          this.channelId
        );
        if (!this.endpoint.id) {
          return;
        }

        await this.$store.dispatch("getIncidents", {
          endpointId: this.endpoint.id,
        });
        const topicCheckUpLifeCycle = await this.$store.dispatch(
          "checkUpLifeCycle",
          {
            endpointId: this.endpoint.id,
            vm: this,
          }
        );
        if (topicCheckUpLifeCycle) {
          this.topicCheckUpLifeCycle = topicCheckUpLifeCycle;
        }
        if (this.endpoint.type === "peer") {
          await this.getterChannels();
          await this.getterChainCodes();
        }
        await this.getterAvailability();
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getterChannels(fabricAdminTool) {
      if (!fabricAdminTool) {
        this.bNetID = await this.$store.dispatch(
          "getBlockchainId",
          this.endpoint.id
        );
      }
      const channelTopicId = await this.$store.dispatch("getChannelsList", {
        endpointId: this.endpoint.id,
        bNetID: fabricAdminTool || this.bNetID,
        vm: this,
      });

      if (channelTopicId) {
        this.channelTopicId = channelTopicId;
      }
    },
    async getterChainCodes(fabricAdminTool) {
      if (!fabricAdminTool) {
        this.bNetID = await this.$store.dispatch(
          "getBlockchainId",
          this.endpoint.id
        );
      }
      const chainCodesTopicId = await this.$store.dispatch("getChainCodes", {
        endpointId: this.endpoint.id,
        bNetID: fabricAdminTool || this.bNetID,
        vm: this,
      });

      if (chainCodesTopicId) {
        this.chainCodesTopicId = chainCodesTopicId;
      }
    },
    async getterAvailability() {
      const availabilityTopicId = await this.$store.dispatch(
        "getAvailability",
        {
          endpointId: this.endpoint.id,
          vm: this,
        }
      );
      if (availabilityTopicId) {
        this.availabilityTopicId = availabilityTopicId;
      }
    },
    async getBlockchainId() {
      try {
        if (this.endpoint.id) {
          await this.$store.dispatch("getBlockchainNetId", this.endpoint.id);
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async lifecycleRefresh() {
      try {
        if (this.endpoint.id) {
          const topicCheckUpLifeCycle = await this.$store.dispatch(
            "checkUpLifeCycle",
            {
              endpointId: this.endpoint.id,
              vm: this,
            }
          );
          if (topicCheckUpLifeCycle) {
            this.topicCheckUpLifeCycle = topicCheckUpLifeCycle;
          }
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async lifecycleUp() {
      try {
        this.$store.commit("lifeCycleReset");
        const topicCheckUpLifeCycle = await this.$store.dispatch(
          "lifecycleUp",
          {
            endpointId: this.endpoint.id,
            vm: this,
          }
        );
        if (topicCheckUpLifeCycle) {
          this.topicCheckUpLifeCycle = topicCheckUpLifeCycle;
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async lifecycleStart() {
      try {
        this.$store.commit("lifeCycleReset");
        const topicCheckUpLifeCycle = await this.$store.dispatch(
          "lifecycleStart",
          {
            endpointId: this.endpoint.id,
            vm: this,
          }
        );
        if (topicCheckUpLifeCycle) {
          this.topicCheckUpLifeCycle = topicCheckUpLifeCycle;
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async lifecycleStop() {
      try {
        this.$store.commit("lifeCycleReset");
        const topicCheckUpLifeCycle = await this.$store.dispatch(
          "lifecycleStop",
          {
            endpointId: this.endpoint.id,
            vm: this,
          }
        );
        if (topicCheckUpLifeCycle) {
          this.topicCheckUpLifeCycle = topicCheckUpLifeCycle;
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async lifecycleDown() {
      try {
        this.$store.commit("lifeCycleReset");
        const topicCheckUpLifeCycle = await this.$store.dispatch(
          "lifecycleDown",
          {
            endpointId: this.endpoint.id,
            vm: this,
          }
        );
        if (topicCheckUpLifeCycle) {
          this.topicCheckUpLifeCycle = topicCheckUpLifeCycle;
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getFabricAdminTool() {
      try {
        const param = `status=attached&current_org=true&type=fabric-admin-tools`;
        this.modalParam.fabricAdminToolList = await this.$store.dispatch(
          "fetchEndpoints",
          param
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    getLastUpdate(lastUpdate) {
      return moment(lastUpdate).format("HH:mm");
    },
    async onCloseModal() {
      this.isShowModal = false;
    },
    async showModalChannels() {
      await this.getFabricAdminTool();
      this.isShowModal = true;
      this.updateFabricToolAction = "channel";
    },
    async showModalChainCodes() {
      await this.getFabricAdminTool();
      this.isShowModal = true;
      this.updateFabricToolAction = "chainCode";
    },
    async showModalConnectChannel(item) {
      await this.getFabricAdminTool();
      this.isShowModal = true;
      this.updateFabricToolAction = "connect";
      if (item.item && item.item.id) {
        this.channelId = item.item.id;
      }
    },

    async onUpdateFabricTool(param) {
      if (this.updateFabricToolAction === "channel") {
        await this.getterChannels(param.fabric_endpoint_id);
      } else if (this.updateFabricToolAction === "chainCode") {
        await this.getterChainCodes(param.fabric_endpoint_id);
      } else {
        if (this.channelId) {
          await this.$store.dispatch("connectChannel", {
            id: this.endpoint.id,
            channel_id: this.channelId,
            fabric_admin_tool_endpoint_id: param.fabric_endpoint_id,
            vm: this,
          });
          // await this.getterChannels(param.fabric_endpoint_id);
        }
      }
    },
    goToTopic() {
      this.$router.push({ path: `/topics/${this.getEndpointTopicId}` });
    },
    goToTopicLog(id) {
      if (id) {
        window.open(`/topics/${id}`);
      }
    },
  },
  destroyed() {
    this.status = null;
  },
};
</script>
<style>
.inlineBox {
  display: inline;
}

.bold {
  font-weight: bold;
}

.animated {
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

.syncAnimated {
  animation: spin 2s linear infinite;
  margin: 0 10px;
}

.statusMargin {
  margin: 0 5px;
}

.statusStyles {
  margin: 0 10px;
  cursor: pointer;
}

.alignEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.connectColor {
  color: blue;
  cursor: pointer;
}

.statusStylesSync {
  margin: 0 10px;
  cursor: pointer;
  animation: spin 2s linear infinite;
}

.clearButton {
  background: transparent;
  border: none;
}

.clearButton:focus {
  outline: none;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
